import React from "react";
import { GenericError } from "../../components/GenericError/GenericError";
import { Layout } from "../../components/Layout";

// Key: 01000100 01000110 01010110 01010110 01011000 01010011 01001111 01000101 00101110 01010010 01010101 01001010 00101111 01001001 01001100 01010001 01000100 01001111 01010110 00101111 01011010 01001000 01001111 01000110 01010010 01010000 01001000 01000010 01000101 01010111 01011010 01001000 01001111 01011001 01001000 01010011 01010101 01010010 01010000 01000100 01000001
// How to decrypt: convert to decimal, caesar-shift - 3, then map to ASCII

const SecretExecTeamIsTheBestTeam: React.FC = () => {
  return (
    <Layout hasFooter={false}>
      <GenericError
        error_message={"Welcome, YB 12!"}
        sub_message={"...our knowledge and wisdom through discipline..."}
      />
    </Layout>
  );
};

export default SecretExecTeamIsTheBestTeam;
